function signInWithGoogle(data) {
    $.ajax({
        type: "POST",
        url: apiUrl + 'auth/login/google',
        data: {credential: data.credential, client_id: data.client_id},
        success: function (data) {
            if (data.status == 'success') {
                if (typeof data.user != 'undefined') {
                    if (typeof data.user.access_token != 'undefined') {
                        localStorage.setItem('bearer', data.user.access_token);
                        window.location = '/';
                    }
                }
            } else {
                console.log(data);
            }
        }
    });
}